import BpToast from '@/components/toast/BpToasts';
import { availableLanguages, getTranslatedKey, Translated } from '@/translation';
import { $gettext } from 'vue-gettext';
import Youtube, { YoutubeVideo } from 'youtube.ts';

const youtube = new Youtube(process.env.VUE_APP_YOUTUBE_DATA_API_V3);

type LocalizedYoutubeVideo = YoutubeVideo & {
  localizations?: Record<string, { title: string, description: string }>
}

export async function fetchVideo(id: string) {
  try {
    const video = await youtube.videos.get(id, { part: 'snippet, localizations' }) as LocalizedYoutubeVideo;

    const name: Translated<string> = {};
    const description: Translated<string> = {};
    for (const lang of Object.keys(availableLanguages)) {
      const key = getTranslatedKey(lang);
      const localization = video.localizations && Object.entries(video.localizations).find(([l]) => l.startsWith(lang));

      name[key] = (localization ? localization[1].title : video.snippet.title) || '';
      description[key] = `<p>${localization && localization[1].description || video.snippet.description}</p>`
        .replace(/(https?:\/\/[^\s\n]+)/g, '<a href="$1" target="_blank">$1</a>')
        .replaceAll('\n', '</p><p>');
    }

    BpToast.show({
      color: 'green',
      title: $gettext('Fetch successful'),
      content: $gettext('The data of the video with the ID <strong>%{id}</strong> was successfully fetched.', { id }),
      icon: 'circle-check',
    });

    return {
      name,
      description,
      tags: Array.from(new Set((video.snippet.tags || []).map(tag => tag.toLowerCase())))
    }
  } catch (error: unknown) {
    BpToast.show({
      color: 'red',
      title: $gettext('Fetch failed'),
      content: $gettext('The data of the video with the ID <strong>%{id}</strong> could not be fetched: %{error}', { id, error: (error as Error).message }),
      icon: 'circle-xmark',
    })

    return {
      name: {},
      description: {},
      tags: [],
    };
  }
}